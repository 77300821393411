<template>
  <div class="contact-form-container">
    <div
      class="container"
      :style="formHeight ? `min-height: ${formHeight}px` : ''"
    >
      <FormSuccess v-if="submitted" class="success-container" />
      <form
        action="#"
        ref="contactForm"
        @submit.prevent="submitContactForm"
        :class="{ submitted: submitted }"
        id="contactForm"
      >
        <div class="form-input full">
          <label for="companyName">Company Name</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            v-model="fields.message.companyName"
            required
          />
        </div>

        <div class="form-input full">
          <label for="fullName">Your Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            v-model="fields.message.fullName"
            required
          />
        </div>

        <div class="form-input full">
          <label for="phone">Phone #</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            v-model="fields.message.phoneNumber"
          />
        </div>

        <div class="form-input full">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            v-model="fields.fromEmail"
            required
          />
        </div>

        <div class="form-input full">
          <label for="message">Message</label>
          <textarea
            id="message"
            name="message"
            rows="8"
            v-model="fields.message.body"
            required
          ></textarea>
        </div>

        <div class="form-input full">
          <FormError :error="error" />
        </div>

        <div class="button-group">
          <button>Submit</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { stringify } from 'qs'
import FormError from '@/components/Forms/FormError'
import FormSuccess from '@/components/Forms/FormSuccess'
export default {
  name: 'ContactForm',
  components: { FormSuccess, FormError },
  props: {
    subject: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      formHeight: null,
      fields: {
        action: 'contact-form/send',
        subject: 'Contact Form Submission',
        fromName: '',
        fromEmail: '',
        message: {
          companyName: '',
          fullName: '',
          phoneNumber: '',
          body: ''
        }
      },
      error: '',
      submitting: false,
      submitted: false
    }
  },
  mounted() {
    this.collectFormHeight()

    window.addEventListener('resize', this.collectFormHeight, true)
  },
  methods: {
    collectFormHeight() {
      if (this.submitted) return

      this.formHeight = this.$refs.contactForm.clientHeight
    },
    submitContactForm() {
      if (this.submitting) return

      this.submitting = true
      this.error = ''

      if (window.Craft) {
        this.fields[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue
      }

      if (this.subject.length) {
        this.fields.subject = this.subject
      }

      this.fields.fromName = this.fields.message.fullName

      this.axios
        .post('/', stringify(this.fields), { baseURL: '/' })
        .then(() => {
          this.submitted = true
        })
        .catch(error => {
          const response = error.response

          if (response && response.data && response.data.error) {
            this.error = response.data.error
          } else {
            this.error =
              'Something went wrong while trying to submit the form. Please try again.'
          }
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-form-container {
  .container {
    @apply relative;

    .success-container {
      @apply absolute left-0 right-0;
      top: 50%;
      bottom: 50%;
    }

    #contactForm {
      @apply flex flex-wrap mx-8 mb-32;

      &.submitted {
        @apply opacity-0 hidden;
      }

      .form-input {
        @apply flex flex-col justify-end items-start mb-6;

        &.half {
          @apply w-full;
        }

        &.full {
          @apply w-full;
        }

        &.pr {
          @apply pr-0;
        }

        &.pl {
          @apply pl-0;
        }

        label {
          @apply block text-white uppercase;
          font-size: 1.5em;
          letter-spacing: 2px;
        }

        input,
        textarea {
          @apply block w-full p-2;
          background-color: #b3bac1;
          border: 5px solid #ffffff;
          color: #ffffff;
          transition: all 0.3s ease-in;

          &:focus {
            border: 5px solid #ffffff;
            outline: none;
          }
        }
      }

      .button-group {
        @apply w-full flex justify-start;

        button {
          @apply text-white px-16 py-2 uppercase;
          font-size: 1.3em;
          line-height: 1.5em;
          letter-spacing: 5px;
          background-color: #525e6e;
          box-shadow: 0 0 15px #000000;
          transition: all 0.3s ease-in;

          &:hover,
          &:focus {
            box-shadow: 0 0 4px #000000;
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      #contactForm {
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      #contactForm {
        .form-input {
          @apply mb-6;

          &.half {
            @apply w-1/2;
          }

          &.pr {
            @apply pr-3;
          }

          &.pl {
            @apply pl-3;
          }

          label {
            font-size: 1.8em;
            letter-spacing: 2px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      #contactForm {
        .form-input {
          @apply mb-6;
        }
      }
    }
  }
}
</style>
