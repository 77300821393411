<template>
  <div id="contactPage">
    <ContactHeroContainer
      v-if="heroData"
      @loaded="toggleShowPageTrue"
      :hero-data="heroData"
    />
  </div>
</template>

<script>
import api from '@/api'
import ContactHeroContainer from '@/components/Hero/ContactHeroContainer'
import viewMixin from '@/mixins/viewMixin'
import apiHelper from '@/api/helpers/helpers'

export default {
  name: 'Contact',
  components: {
    ContactHeroContainer
  },
  mixins: [viewMixin],
  data() {
    return {
      heroData: null
    }
  },
  mounted() {
    this.loadContactPage()
  },
  methods: {
    loadContactPage() {
      this.setContactData(apiHelper.readStoredResponse('contact'))

      api.contact.loadContactPage().then(response => {
        this.setContactData(response)
      })
    },
    setContactData(response) {
      if (!response || !response.entry) return

      this.heroData = response.entry.contactHero[0]
    }
  }
}
</script>
