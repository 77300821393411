<template>
  <div
    class="contact-hero-container"
    :style="
      `background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(82, 94, 110, 0.9)), url(${backgroundImageUrl});`
    "
  >
    <div class="container">
      <div class="contact-buttons-container">
        <div>
          <a :href="`tel:${heroData.phoneNumber}`">
            <img src="@/assets/buttons/phone-button.png" alt="Call Us Button" />
            CALL&nbsp;US
          </a>
        </div>
        <div>
          <a :href="`${heroData.linkedinUrl}`" target="_blank">
            <img
              src="@/assets/buttons/linkedin-button.png"
              alt="LinkedIn Button"
            />
            LINKEDIN
          </a>
        </div>
      </div>

      <div class="contact-form-wrapper">
        <ContactForm />
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from '@/components/Forms/ContactForm'
export default {
  name: 'ContactHeroContainer',
  components: { ContactForm },
  props: {
    heroData: {
      type: Object,
      required: true
    }
  },
  computed: {
    backgroundImageUrl() {
      return this.heroData ? this.heroData.backgroundImage[0].url : ''
    }
  },
  watch: {
    backgroundImageUrl(newVal) {
      if (newVal.length) {
        this.preloadImage()
      }
    }
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      if (!this.backgroundImageUrl.length) return

      const preloader = document.createElement('img')

      preloader.addEventListener('load', () => {
        this.$emit('loaded')
      })

      preloader.src = this.backgroundImageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-hero-container {
  @apply bg-cover bg-no-repeat bg-center;

  .container {
    @apply relative h-full flex flex-col-reverse pt-16;

    .contact-form-wrapper {
      @apply w-full;
    }

    .contact-buttons-container {
      @apply w-full flex flex-col items-end justify-center w-full;
      padding-bottom: 30px;

      div {
        @apply inline-block;
        padding: 16px 32px 16px 0;

        a,
        a:visited,
        a:active {
          @apply text-white w-full;
          font-size: 16px;
          letter-spacing: 4px;
          transition: color 0.3s linear;

          &:hover {
            color: rgba(255, 255, 255, 0.5);

            img {
              opacity: 0.5;
            }
          }

          img {
            max-width: 125px;
            transition: opacity 0.3s linear;
            opacity: 1;
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .contact-buttons-container {
        div {
          a,
          a:visited,
          a:active {
            font-size: 27px;

            img {
              max-width: 175px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      @apply flex-row;

      .contact-form-wrapper {
        @apply w-1/2;
      }

      .contact-buttons-container {
        @apply flex-row w-1/2 right-0;
        top: 82vh;
        padding-bottom: 120px;

        div {
          @apply inline-block;
          padding: 0;

          &:not(:first-of-type) {
            padding-left: 20px;
          }

          &:not(:last-of-type) {
            padding-right: 20px;
          }

          &:last-of-type {
            padding-right: 100px;
          }

          a,
          a:visited,
          a:active {
            img {
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .container {
      .contact-form-wrapper {
        @apply w-1/2;
      }

      .contact-buttons-container {
        @apply w-1/2;
      }
    }
  }
}
</style>
