<template>
  <div :class="[{ show: show }, 'form-success']">
    <font-awesome-icon class="icon" icon="check-circle" />
    <p>{{ successMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'FormSuccess',
  props: {
    successMessage: {
      type: String,
      default: () => 'Thank you for your form submission!'
    }
  },
  data() {
    return {
      show: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.show = true
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.form-success {
  color: #ffffff;
  font-size: 1.3em;
  opacity: 0;
  transition: opacity 0.5s ease-in;

  &.show {
    opacity: 1;
  }

  .icon {
    font-size: 6em;
    color: #ffffff;
  }

  p {
    @apply mt-6;
    font-family: 'Inter SemiBold', sans-serif;
    letter-spacing: 1px;
  }
}
</style>
